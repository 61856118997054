import React from "react";

function BrokerageSlab(props) {
    const { data, head } = props;
    return (
        // <div className={mainclass}>
        //     <div className={subclass}>
        //         <div className={amountclass}> <span> ₹</span>{amount}<sub>{unit}</sub></div>
        //         <p className={pclass}>{plabel}</p>
        //         <div className={{ offerclass }}>{label}<br /> <span>{spanlabel}</span></div>
        //     </div>
        // </div>

        <div className="overflow-auto pl-3 pr-3">
            <table>
                <thead >
                    {
                        head.map((brok, index) => (
                            <tr className="tblhead">

                                <th>{brok.description_1}</th>
                                <th>{brok.description_2}</th>
                                <th>{brok.description_3}</th>
                                <th>{brok.description_4}</th>
                                <th>{brok.description_5}</th>


                            </tr>

                        ))
                    }
                </thead>
                <tbody >
                    {
                        data.map((brok, index) => (
                            <tr className="tbltbody">

                                <td>{brok.description_1}</td>
                                <td className="text-center">{brok.description_2}</td>
                                <td className="text-center">{brok.description_3}</td>
                                <td className="text-center">{brok.description_4}</td>
                                <td className="text-center">{brok.description_5}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>

    );
}

export default BrokerageSlab;
