import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import RadoiButtonGroup from "../Component/RadioButtonGroup";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import { MasterService } from "../Services/MasterService";
import { throttle } from "lodash";
import PennydropSpin from "../Component/PennydropSpin";
import { PATH } from "../Common/Path";
import { getCurrentStep, getPath } from "../Common/utils";
import PincodeBoxV2 from "../Component/PincodeBoxV2";

const BankDetails = (props) => {
  const {
    setLoading,
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
    setHasChanges,
  } = useContext(AppContext);
  const [errors, setErrors] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [options, setOptions] = React.useState([]);
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);
  const [apiDisabledFields, setAPIDisabledFields] = useState([]);
  const [visibleVerifyBtn, setVisibleVerifyBtn] = useState(true);
  const [visibleSubmitBtn, setVisibleSubmitBtn] = useState(false);
  const [visibleBankMainAddress, setVisibleBankMainAddress] = useState(false);
  const [visibleBankName, setVisibleBankName] = useState(false);
  const [visibleMICR, setVisibleMICR] = useState(false);
  const [visibleAccHolder, setVisibleAccHolder] = useState(false);
  const [accNameDisabled, setAccNameDisabled] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showPennydrop, setShowPennydrop] = useState(false);
  const [showPincodeNote, setShowPincodeNote] = useState(false);
  const currentRef = useRef();

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    LeadService.getBankDetails()
      .then((data) => {
        setCurrentStep(getCurrentStep(data.current_step));
        if (data.success) {
          let formData = {};
          if (data.penny_drop === 1) {
            formData.txtAccHolder = data.account_holder_name;
            formData.rdAccTye =
              data.bank_account_type === "Savings"
                ? RadioGroupOptions.ACC_TYPE[0]
                : data.bank_account_type === "Current"
                  ? RadioGroupOptions.ACC_TYPE[1]
                  : data.bank_account_type === "Others"
                    ? RadioGroupOptions.ACC_TYPE[2]
                    : "";
            formData.txtAccHolder = data.account_holder_name;
            formData.txtAccNo = data.bank_account_no;
            formData.txtBank = data.bank_name;
            formData.ac_holdername = data.account_holder_name;
            formData.txtIFC_code = data.bank_ifsc;
            formData.txtMICE = data.bank_micr;
            formData.txtBuilding = data.bank_addr1;
            formData.txtLocality = data.bank_addr2;
            formData.txtStreet = data.bank_addr3;
            formData.permanent_pincode = data.bank_pincode;
            formData.permanent_state = data.bank_state;
            formData.permanent_city = data.bank_city;
            setAPIDisabledFields(data.disabled_fields || []);
            if (!data.account_holder_name) {
              setAccNameDisabled(false);
            } else {
              setAccNameDisabled(true);
            }
            setVisibleAccHolder(true);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          } else if (data.penny_drop === 0) {
            setVisibleAccHolder(true);
            setAccNameDisabled(false);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          } else {
            setVisibleAccHolder(false);
            setVisibleVerifyBtn(true);
            setVisibleSubmitBtn(false);
            setShowEditAccount(false);
          }

          if (data.micr_fetched === 1) {
            setVisibleMICR(false);
            formData.txtMICE = data.bank_micr;
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          } else if (data.micr_fetched === 0) {
            setVisibleMICR(true);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          } else {
            setVisibleMICR(false);
            setVisibleVerifyBtn(true);
            setVisibleSubmitBtn(false);
            setShowEditAccount(false);
          }
          if (data.bank_fetched === 0) {
            setVisibleBankMainAddress(true);
            setVisibleBankName(true);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          } else if (data.bank_fetched === 1 && data.bank_pincode_fetched === 0) {
            setVisibleBankMainAddress(true);
            setVisibleBankName(true);
            setShowPincodeNote(true);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code", "txtBank", "txtBuilding", "txtLocality", "txtStreet"]);
            setAPIDisabledFields(["bank_city", "bank_state"]);
            formData.txtBank = data.bank_name;
            formData.txtBuilding = data.bank_addr1;
            formData.txtLocality = data.bank_addr2;
            formData.txtStreet = data.bank_addr3;
            formData.permanent_state = data.bank_state;
            formData.permanent_city = data.bank_city;
          } else if (data.bank_fetched === 1) {
            setVisibleBankMainAddress(false);
            setVisibleBankName(false);
            formData.txtBank = data.bank_name;
            formData.txtBuilding = data.bank_addr1;
            formData.txtLocality = data.bank_addr2;
            formData.permanent_pincode = data.bank_pincode;
            formData.permanent_state = data.bank_state;
            formData.permanent_city = data.bank_city;
            formData.txtStreet = data.bank_addr3;
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            setShowEditAccount(true);
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
          }

          setFormData((old) => ({ ...old, ...formData }));
        } else {
          setShowEditAccount(false);
          formData.rdAccTye = "";
          formData.txtAccHolder = "";
          formData.txtAccNo = "";
          formData.txtBank = "";
          formData.txtBuilding = "";
          formData.txtIFC_code = "";
          formData.txtLocality = "";
          formData.txtMICE = "";
          formData.permanent_pincode = "";
          formData.permanent_state = "";
          formData.permanent_city = "";
          formData.txtStreet = "";
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
    currentRef.current.focus();
  }, [currentStep]);

  /**
   * validation for verify
   */

  const mandatoryValidationVerify = () => {
    let isValid = true;
    if (!formData.rdAccTye) {
      setErrors((oldErrors) => ({ ...oldErrors, rdAccTye: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, rdAccTye: false }));
    }
    if (formData.txtAccNo) {
      formData.txtAccNo = formData.txtAccNo.trim();
    }

    if (!formData.txtAccNo) {
      setErrors((oldErrors) => ({ ...oldErrors, txtAccNo: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtAccNo: false }));
    }
    if (formData.txtIFC_code) {
      formData.txtIFC_code = formData.txtIFC_code.trim();
    }
    if (!formData.txtIFC_code) {
      setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: false }));
    }
    return isValid;
  };

  /**
   * cverify click
   */
  const verifyBank = () => {
    if (
      mandatoryValidationVerify() &&
      validateAccount(formData.txtAccNo) &&
      validateIFC(formData.txtIFC_code)
    ) {
      setShowPennydrop(true);
      let details = {
        bank_account_type: formData.rdAccTye,
        bank_account_no: formData.txtAccNo,
        bank_ifsc: formData.txtIFC_code,
      };
      setApiMessage("");
      LeadService.verifyBankAccount({ details })
        .then((data) => {
          if (data.success) {
            setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code"]);
            let formData = {};
            if (data.penny_drop === 1) {
              formData.txtAccHolder = data.account_holder_name;
              if (!data.account_holder_name) {
                setAccNameDisabled(false);
              } else {
                setAccNameDisabled(true);
              }
              setVisibleAccHolder(true);
              setVisibleSubmitBtn(true);
              setShowEditAccount(true);
              setVisibleVerifyBtn(false);
            } else if (data.penny_drop === 0) {
              setVisibleAccHolder(true);
              setAccNameDisabled(false);
              setVisibleSubmitBtn(true);
              setVisibleVerifyBtn(false);
              setShowEditAccount(true);
            } else {
              setVisibleAccHolder(false);
              setVisibleVerifyBtn(true);
              setVisibleSubmitBtn(false);
              setShowEditAccount(false);
            }

            if (data.micr_fetched === 1) {
              setVisibleMICR(false);
              formData.txtMICE = data.bank_micr;
              setVisibleSubmitBtn(true);
              setShowEditAccount(true);
              setVisibleVerifyBtn(false);
            } else if (data.micr_fetched === 0) {
              setVisibleMICR(true);
              setVisibleSubmitBtn(true);
              setShowEditAccount(true);
              setVisibleVerifyBtn(false);
            } else {
              setVisibleMICR(false);
              setVisibleVerifyBtn(true);
              setVisibleSubmitBtn(false);
              setShowEditAccount(false);
            }

            if (data.bank_fetched === 0) {
              setVisibleBankMainAddress(true);
              setVisibleBankName(true);
              setShowEditAccount(true);
            } else if (data.bank_fetched === 1 && data.bank_pincode_fetched === 0) {
              setVisibleBankMainAddress(true);
              setVisibleBankName(true);
              setShowPincodeNote(true);
              setShowEditAccount(true);
              setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code", "txtBank", "txtBuilding", "txtLocality", "txtStreet"]);
              setAPIDisabledFields(["bank_city", "bank_state"]);
              formData.txtBank = data.bank_name;
              formData.txtBuilding = data.bank_addr1;
              formData.txtLocality = data.bank_addr2;
              formData.txtStreet = data.bank_addr3;
              formData.permanent_pincode = data.permanent_pincode;
              formData.permanent_state = data.bank_state;
              formData.permanent_city = data.bank_city;
            } else {
              setVisibleBankMainAddress(false);
              setVisibleBankName(false);
              formData.txtBank = data.bank_name;
              formData.txtBuilding = data.bank_addr1;
              formData.txtLocality = data.bank_addr2;
              formData.txtStreet = data.bank_addr3;
              formData.permanent_pincode = data.bank_pincode;
              formData.permanent_state = data.bank_state;
              formData.permanent_city = data.bank_city;
              setVisibleSubmitBtn(true);
              setVisibleVerifyBtn(false);
              setShowEditAccount(true);
            }

            setFormData((old) => ({ ...old, ...formData }));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setShowPennydrop(false));
    }
  };

  /**
   * validation for final submit
   */

  const mandatoryValidationSubmit = () => {
    let isValid = true;
    if (formData.txtAccHolder) {
      formData.txtAccHolder = formData.txtAccHolder.trim();
    }

    if (!formData.txtAccHolder) {
      setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: false }));
    }

    if (formData.txtBank) {
      formData.txtBank = formData.txtBank.trim();
    }

    if (!formData.txtBank) {
      setErrors((oldErrors) => ({ ...oldErrors, txtBank: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtBank: false }));
    }
    if (formData.txtBuilding) {
      formData.txtBuilding = formData.txtBuilding.trim();
    }
    if (!formData.txtBuilding) {
      setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: false }));
    }
    if (formData.permanent_city) {
      formData.permanent_city = formData.permanent_city.trim();
    }

    if (!formData.permanent_city) {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_city: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_city: false }));
    }
    if (formData.permanent_state) {
      formData.permanent_state = formData.permanent_state.trim();
    }
    if (!formData.permanent_state) {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_state: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_state: false }));
    }
    // if (formData.txtStreet) {
    //     formData.txtStreet = formData.txtStreet.trim();
    // }
    // if (!formData.txtStreet) {
    //     setErrors(oldErrors => ({ ...oldErrors, txtStreet: true }))
    //     isValid = false;
    // }
    // else {
    //     setErrors(oldErrors => ({ ...oldErrors, txtStreet: false }))
    // }

    if (formData.txtIFC_code) {
      formData.txtIFC_code = formData.txtIFC_code.trim();
    }
    if (!formData.txtIFC_code) {
      setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: false }));
    }
    if (formData.txtLocality) {
      formData.txtLocality = formData.txtLocality.trim();
    }
    if (!formData.txtLocality) {
      setErrors((oldErrors) => ({ ...oldErrors, txtLocality: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtLocality: false }));
    }

    if (formData.txtMICE) {
      formData.txtMICE = formData.txtMICE.trim();
    }
    if (!formData.txtMICE) {
      setErrors((oldErrors) => ({ ...oldErrors, txtMICE: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, txtMICE: false }));
    }
    // if (formData.permanent_pincode) {
    //     formData.permanent_pincode = formData.permanent_pincode.trim();
    // }
    if (!formData.permanent_pincode) {
      setVisibleBankMainAddress(true);
      setVisibleBankName(true);
      setShowPincodeNote(true);
      setDisabledFields(["rdAccTye", "txtAccNo", "txtIFC_code", "txtBank", "txtBuilding", "txtLocality", "txtStreet"]);
      setAPIDisabledFields(["bank_city", "bank_state"]);
      isValid = false;
    }
    else {
      setErrors(oldErrors => ({ ...oldErrors, permanent_pincode: false }))
    }
    return isValid;
  };

  /**
   * next click
   */

  const nextClick = () => {
    //console.log(formData.txtAccHolder, '...', formData.txtAccNo, '...', formData.txtBank, '...', formData.txtBuilding, '...', formData.permanent_city, '...', formData.txtIFC_code, '...', formData.txtLocality, '...', formData.txtMICE, '...', formData.permanent_pincode, '...', formData.permanent_state, '...', formData.txtStreet, '...', formData.rdAccTye);
    if (mandatoryValidationSubmit()) {
      setLoading(true);
      let body = {
        details: {
          bank_account_type: formData.rdAccTye,
          bank_account_no: formData.txtAccNo,
          bank_ifsc: formData.txtIFC_code,
          account_holder_name: formData.txtAccHolder,
          bank_micr: formData.txtMICE,
          bank_name: formData.txtBank,
          bank_addr1: formData.txtBuilding,
          bank_addr2: formData.txtLocality,
          bank_addr3: formData.txtStreet,
          bank_city: formData.permanent_city,
          bank_state: formData.permanent_state,
          bank_country: "India",
          bank_pincode: formData.permanent_pincode,
        },
      };
      setLoading(true);
      setApiMessage("");
      LeadService.submit_about_bank_details(body)
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * previous button
   */
  const prevClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(PATH.BANKACCOUNT_PAGE);
      });
    } else {
      history.push(PATH.BANKACCOUNT_PAGE);
    }
  };
  const pinHandleChange = (event) => {
    let active = true;
    if (event.target.value) {
      fetch({ input: event.target.value }, (results) => {
        if (active) {
          let newOptions = [];
          // if (value) {
          //     newOptions = [value];
          // }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setHasChanges(true);
    }
  };

  /**
   * checkbox onchange function
   */
  const handleEditClick = (event) => {
    setDisabledFields([]);
    setVisibleBankMainAddress(false);
    setVisibleBankName(false);
    setVisibleMICR(false);
    setVisibleAccHolder(false);
    setAccNameDisabled(false);
    setVisibleVerifyBtn(true);
    setVisibleSubmitBtn(false);
    setShowEditAccount(false);
  };
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        MasterService.getPincodeList(request.input).then((data) =>
          callback(data)
        );
      }, 200),
    []
  );
  const pincodeSelect = (field, location) => {
    if (field === "permanent_pincode") {
      setFormData((oldData) => ({
        ...oldData,
        [field]: location.pincode,
        permanent_city: location.city,
        permanent_state: location.state,
      }));
    }
    setOptions([]);
  };
  /**
   * validation
   * @param {*} event
   */
  const validateAccount = (account) => {
    //setLoading(true);
    if (account.length >= 9 && account.length <= 18) {
      setApiMessage(" ");
      return true;
    } else {
      setApiMessage(strings.invalidAccno);
      return false;
    }
  };
  const validateIFC = (ifsc) => {
    if (ifsc.length === 11) {
      setApiMessage(" ");
      return true;
    } else {
      setApiMessage(strings.ivalidifc);
      return false;
    }
  };

  /**
   * onchange
   * @param {} event
   */
  const handleChange = (event) => {
    //event.persist();
    setApiMessage("");
    if (
      event.target.name === "rdAccTye" ||
      event.target.name === "txtAccHolder" ||
      event.target.name === "txtBank" ||
      event.target.name === "txtBuilding" ||
      event.target.name === "permanent_city" ||
      event.target.name === "txtLocality" ||
      event.target.name === "txtMICE" ||
      event.target.name === "permanent_pincode" ||
      event.target.name === "permanent_state" ||
      event.target.name === "txtStreet"
    ) {
      if (event.target.value) {
        setFormData((oldData) => ({
          ...oldData,
          [event.target.name]: event.target.value,
        }));
      }
    }

    if (event.target.name === "txtAccNo") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, txtAccNo: false }));
    }
    if (event.target.name === "txtIFC_code") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: false }));
    }

    if (event.target.name === "rdAccTye") {
      setErrors((oldErrors) => ({ ...oldErrors, rdAccTye: false }));
    }
    if (event.target.name === "txtAccHolder") {
      setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: false }));
    }
    if (event.target.name === "txtBank") {
      setErrors((oldErrors) => ({ ...oldErrors, txtBank: false }));
    }
    if (event.target.name === "txtBuilding") {
      setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: false }));
    }
    if (event.target.name === "permanent_city") {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_city: false }));
    }
    if (event.target.name === "txtLocality") {
      setErrors((oldErrors) => ({ ...oldErrors, txtLocality: false }));
    }
    if (event.target.name === "txtMICE") {
      setErrors((oldErrors) => ({ ...oldErrors, txtMICE: false }));
    }
    if (event.target.name === "permanent_pincode") {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_pincode: false }));
    }
    if (event.target.name === "permanent_state") {
      setErrors((oldErrors) => ({ ...oldErrors, permanent_state: false }));
    }
    if (event.target.name === "txtStreet") {
      setErrors((oldErrors) => ({ ...oldErrors, txtStreet: false }));
    }
    setHasChanges(true);
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />

            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-2">{strings.bankdetail}</h5>
                  {/* <p>{strings.banksubhead}</p> */}
                </div>
                {showEditAccount && (
                  <div className="col-md-12 bank_pencil">
                    <label>
                      <p className="paddingleft">
                        <img
                          src={process.env.PUBLIC_URL + "/img/pen.png"}
                          style={{ width: "18px", marginRight: "5px" }}
                          alt=""
                        />
                        <a href="# " onClick={handleEditClick}>
                          {strings.doyowantedit}
                        </a>
                      </p>
                    </label>
                  </div>
                )}

                <RadoiButtonGroup
                  reference={currentRef}
                  tabIndex="1"
                  mainclass="col-md-6 pb-3 "
                  label={strings.acctype}
                  options={RadioGroupOptions.ACC_TYPE}
                  type="radio"
                  name="rdAccTye"
                  option="rdAccTye"
                  onOptionChange={handleChange}
                  error={errors.rdAccTye}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  selected={formData.rdAccTye || undefined}
                  disabled={disabledFields.includes("rdAccTye")}
                />

                <div className="col-md-6 pb-2">
                  <TextBox
                    tabIndex="2"
                    label={strings.accno}
                    placehold={strings.accno}
                    classes="inuput-box"
                    inputclass="paninput"
                    type="text"
                    textId="txtAccNo"
                    disabled={disabledFields.includes("txtAccNo")}
                    fieldValue={formData.txtAccNo || ""}
                    onTextChange={handleChange}
                    error={errors.txtAccNo}
                    errorclass="text-danger errorfont mh-18"
                    errorMessage={strings.mandatory}
                    postfix={
                      <div className="api-errormessage required" style={{ marginTop: "-10px" }}>
                        {strings.bank_note}
                      </div>
                    }
                  />
                </div>
                <div className="col-md-6 pb-2">
                  <TextBox
                    tabIndex="3"
                    // mainclass="col-md-6 pb-2"
                    label={strings.ifc}
                    placehold={strings.ifc}
                    classes="inuput-box"
                    type="text"
                    textId="txtIFC_code"
                    inputclass="paninput"
                    disabled={disabledFields.includes("txtIFC_code")}
                    fieldValue={formData.txtIFC_code || ""}
                    onTextChange={handleChange}
                    error={errors.txtIFC_code}
                    errorclass="text-danger errorfont mh-18"
                    errorMessage={strings.mandatory}
                  />
                </div>

                {visibleAccHolder && (
                  <TextBox
                    tabIndex="4"
                    mainclass="col-md-6 pb-2"
                    label={strings.ac_holdername}
                    placehold={strings.ac_holdername}
                    classes="inuput-box"
                    type="text"
                    textId="txtAccHolder"
                    inputclass="paninput"
                    // disabled={disabledFields.account_holder_name || false}
                    fieldValue={formData.txtAccHolder || ""}
                    onTextChange={handleChange}
                    error={errors.txtAccHolder}
                    errorclass="text-danger errorfont mh-18"
                    errorMessage={strings.mandatory}
                    disabled={accNameDisabled || false}
                  />
                )}

                {visibleMICR && (
                  <TextBox
                    tabIndex="5"
                    mainclass="col-md-6 pb-2"
                    label={strings.micr}
                    placehold={strings.micr}
                    classes="inuput-box"
                    type="text"
                    textId="txtMICE"
                    inputclass="paninput"
                    disabled={disabledFields.bank_micr || false}
                    fieldValue={formData.txtMICE || ""}
                    onTextChange={handleChange}
                    error={errors.txtMICE}
                    errorclass="text-danger errorfont mh-18"
                    errorMessage={strings.mandatory}
                  />
                )}
                {visibleBankName && (
                  <TextBox
                    tabIndex="6"
                    mainclass="col-md-6 pb-2"
                    label={strings.bankname}
                    placehold={strings.bankname}
                    classes="inuput-box"
                    type="text"
                    textId="txtBank"
                    inputclass="paninput"
                    disabled={disabledFields.includes("txtBank")}
                    // disabled={disabledFields.bank_name || false}
                    fieldValue={formData.txtBank || ""}
                    onTextChange={handleChange}
                    error={errors.txtBank}
                    errorclass="text-danger errorfont mh-18"
                    errorMessage={strings.mandatory}
                  />
                )}
                {visibleBankMainAddress && (
                  <React.Fragment>
                    <div className="col-md-12">
                      <label for="">{strings.bankaddress}</label>
                    </div>

                    <TextBox
                      tabIndex="7"
                      mainclass="col-md-4"
                      label=""
                      placehold={strings.buildingno}
                      classes="inuput-box"
                      type="text"
                      textId="txtBuilding"
                      inputclass="paninput"
                      disabled={disabledFields.includes("txtBuilding")}
                      // disabled={disabledFields.bank_addr1 || false}
                      fieldValue={formData.txtBuilding || ""}
                      onTextChange={handleChange}
                      error={errors.txtBuilding}
                      errorclass="text-danger errorfont mh-18"
                      errorMessage={strings.mandatory}
                    />


                    <TextBox
                      tabIndex="8"
                      mainclass="col-md-4"
                      label=""
                      placehold={strings.bankaddressarea}
                      classes="inuput-box"
                      type="text"
                      textId="txtLocality"
                      inputclass="paninput"
                      disabled={disabledFields.includes("txtLocality")}
                      // disabled={disabledFields.bank_addr3 || false}
                      fieldValue={formData.txtLocality || ""}
                      onTextChange={handleChange}
                      error={errors.txtLocality}
                      errorclass="text-danger errorfont mh-18"
                      errorMessage={strings.mandatory}
                    />

                    <TextBox
                      tabIndex="9"
                      mainclass="col-md-4"
                      label=""
                      placehold={strings.street}
                      classes="inuput-box"
                      type="text"
                      textId="txtStreet"
                      inputclass="paninput"
                      disabled={disabledFields.includes("txtStreet")}
                      // disabled={disabledFields.bank_addr2 || false}
                      fieldValue={formData.txtStreet || ""}
                      onTextChange={handleChange}
                      error={errors.txtStreet}
                      errorclass="text-danger errorfont mh-18"
                      errorMessage={strings.mandatory}
                    />
                    <div className="col-md-4 pb-2">
                      <PincodeBoxV2
                        tabIndex="10"
                        // mainclass="col-md-4 pb-2"
                        placehold={strings.pincode}
                        classes="inuput-box"
                        inputclass="mb-0"
                        type="text"
                        textId="permanent_pincode"
                        textMaxLength="6"
                        disabled={disabledFields.bank_pincode || false}
                        options={options || []}
                        fieldValue={formData.permanent_pincode || ""}
                        onTextChange={pinHandleChange}
                        optionClick={(location) =>
                          pincodeSelect("permanent_pincode", location)
                        }
                        error={errors.permanent_pincode}
                        errorclass="text-danger errorfont mh-18"
                        errorMessage={strings.mandatory}
                      />
                      {showPincodeNote && (
                        <div className="api-errormessage required" style={{ marginTop: "-10px" }}>
                          {strings.pincode_note}
                        </div>
                      )}
                    </div>
                    <TextBox
                      tabIndex="11"
                      mainclass="col-md-4"
                      label=""
                      placehold={strings.city}
                      classes="inuput-box"
                      type="text"
                      textId="permanent_city"
                      inputclass="paninput"
                      disabled={
                        disabledFields.bank_city ||
                        apiDisabledFields.includes("bank_city") ||
                        false
                      }
                      fieldValue={formData.permanent_city || ""}
                      onTextChange={handleChange}
                      error={errors.permanent_city}
                      errorclass="text-danger errorfont mh-18"
                      errorMessage={strings.mandatory}
                    />

                    <TextBox
                      tabIndex="12"
                      mainclass="col-md-4"
                      label=""
                      placehold={strings.state}
                      classes="inuput-box"
                      type="text"
                      textId="permanent_state"
                      inputclass="paninput"
                      disabled={
                        disabledFields.bank_state ||
                        apiDisabledFields.includes("bank_state") ||
                        false
                      }
                      fieldValue={formData.permanent_state || ""}
                      onTextChange={handleChange}
                      error={errors.permanent_state}
                      errorclass="text-danger errorfont mh-18"
                      errorMessage={strings.mandatory}
                    />
                  </React.Fragment>
                )}
                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      {" "}
                      <div className=" inp text-danger api-errormessage text-center">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      <Button
                        classes="inp-back"
                        label={strings.previous}
                        click={prevClick}
                      />
                    </div>
                    <div className="col-6 pt-3 text-right">
                      {visibleVerifyBtn && (
                        <Button
                          classes="inp"
                          label={strings.verify}
                          click={verifyBank}
                          inputType="text"
                        />
                      )}
                      {visibleSubmitBtn && (
                        <Button
                          classes="inp"
                          label={strings.nextbtn}
                          click={nextClick}
                          inputType="text"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {showPennydrop && (
          <div className="loaderContainer">
            <PennydropSpin />
          </div>
        )}
      </div>
    </div>
  );
};
export default BankDetails;
