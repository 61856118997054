import React from 'react'
/**
 * Header for the APP
 * @returns 
 */
function Header() {
  return (
    <div className="header pb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-50">
            <div className="logo-align">
              <a href="https://acumengroup.in/" target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL +"/img/acumen-logo.png"} alt="" />
              </a>
            </div>
          </div>
          <div className="col-md-50" />
        </div>
      </div>
    </div>
  )
}

export default Header
