import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../Common/String";
import { Input } from "../../Component/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";
import { Form } from "../../Component/Form";
import { AppContext } from "../../Context/AppContext";
import AlertBox from "../../Component/AlertBox";
import { LeadService } from "../../Services/LeadService";
import useScript from "../../useScript";
import queryString from "query-string";
import { PATH } from "../../Common/Path";
import Button from "../../Component/Button";
import { Checkbox } from "../../Component/Form/Checkbox";
import PincodeBox from "../../Component/Form/PincodeBox";

const Address = (props) => {
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const { show, cancelButtonAction, onSuccess, permanentAddress } = props;
  const { setLoading, setHasChanges } = useContext(AppContext);

  const [isDisabled, setDisabled] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const addressRef = useRef();

  const schema = object().shape({
    sameaspermenant: bool(),
    flat: string().required(strings.rekyc_error),
    street: string().required(strings.rekyc_error),
    landmark: string().required(strings.rekyc_error),
    pin: string()
      .required(strings.rekyc_error)
      .min(6, strings.rekyc_pin)
      .max(6),
    city: string(),
    state: string(),
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      sameaspermenant: false,
      flat: "",
      street: "",
      landmark: "",
      pin: "",
      city: "",
      state: "",
    },
    resolver,
  });

  useEffect(() => {
    setHasChanges(false);
    if (props.location) {
      let qString = queryString.parse(props.location.search);
      const status = qString.status || null;
      let digioId = null;
      if (status && status === "success") {
        digioId = qString.digio_doc_id;
      }
      if (digioId) {
        updateAddress({ digio_doc_id: digioId });
      }
    }
  }, []);

  useEffect(() => {
    if (show && addressRef && addressRef.current) addressRef.current.focus();
  }, [show]);

  const updateAddress = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    LeadService.rekycPostDigioResponse(body)
      .then((data) => {
        if (data.success) {
          onSuccess();
          resetForm();
        } else {
          setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const onDigilockerClick = () => {
    setApiMessage("");
    setLoading(true);
    LeadService.rekycSubmitDigilockerData()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updateAddress(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            let url = data.redirect_url.split("&theme=", 1);
            window.open(
              `${url[0]}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.REKYC_SUMMARY_PAGE}&theme=${url[1]}`,
              "_self"
            );
          }
        } else {
          setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data) => {
    setLoading(true);
    setApiMessage("");
    const details = {
      current_addr_same: data.sameaspermenant ? "Y" : "N",
      current_addr1: data.flat,
      current_addr2: data.street,
      current_addr3: data.landmark,
      current_city: data.city,
      current_state: data.state,
      current_country: "India",
      current_pincode: data.pin,
    };
    LeadService.rekycSubmitAddressManual({ details })
      .then((data) => {
        if (data.success) {
          onSuccess();
          resetForm();
        } else {
          if (data.invalid_fields) {
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const sameASCurrent = (checked) => {
    setHasChanges(true);
    if (checked) {
      registerForm.reset(permanentAddress);
      setDisabled(true);
    } else {
      registerForm.reset({
        flat: "",
        street: "",
        landmark: "",
        pin: "",
        city: "",
        state: "",
      });
      setDisabled(false);
    }
  };

  const resetForm = () => {
    registerForm.reset({
      sameaspermenant: false,
      flat: "",
      street: "",
      landmark: "",
      pin: "",
      city: "",
      state: "",
    });
    document.getElementById("sameaspermenant").checked = false;
    setDisabled(false);
    setApiMessage(false)
  };

  const onCancel = () => {
    resetForm();
    cancelButtonAction();
  };

  return (
    <div className="loaderContainers">
      <AlertBox
        show={show}
        title={
          <>
            <div className="ml-4 mt-3 w-75">
              <h5 className="update-label">{strings.rekyc_update_label}</h5>
            </div>
            <hr className="mb-2" />
          </>
        }
        showCancelIcon={true}
        cancelButtonAction={onCancel}
        classes="box-sectionx mt-1"
      >
        <div className="container address overflow-auto" id="pd-50">
          <div>
            <Form
              autoFocusElement="flat"
              reactHookForm={registerForm}
              onSubmit={onSubmit}
            >
              <Button
                type="button"
                classes1="addressbtn mt-2"
                click={onDigilockerClick}
                label="Update details as per Aadhaar"
              />
              <hr className="hr-text" data-content="OR" />
              <div className="row pb-3 pl-3">
                <div className="col-md-12 pl-0">
                  <Checkbox
                    tabIndex="101"
                    label={strings.sameaspermaddress}
                    labelClass="sameasper mb-0 -mt-2"
                    classes=""
                    inputClass="sameascheck mt-0"
                    name="sameaspermenant"
                    onChange={sameASCurrent}
                  />
                </div>
                {/* <div style={{maxHeight: '35vh', paddingTop: 24, overflowY: 'scroll'}}> */}
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    reference={addressRef}
                    mainClass=""
                    tabIndex="102"
                    classes="material-textfield mr-2"
                    placeholder=""
                    inputClass="addinput inpzz add-inp"
                    type="text"
                    name="flat"
                    maxLength={50}
                    disabled={isDisabled}
                    label="Flat /House no/ Building"
                    labelClass="lblz flatlbl"
                    errorclass="text-danger errorfont mh-18"
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    tabIndex="103"
                    mainClass="w-97"
                    classes="material-textfield"
                    placeholder=""
                    inputClass=" addinput inpzz add-inp"
                    type="text"
                    name="street"
                    maxLength={50}
                    disabled={isDisabled}
                    label="Street name"
                    labelClass="lblz"
                    errorclass="text-danger errorfont pl-3"
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    tabIndex="104"
                    mainClass=" pt-2"
                    classes="material-textfield mr-2"
                    placeholder=""
                    inputClass="addinput inpzz add-inp"
                    type="text"
                    name="landmark"
                    maxLength={50}
                    disabled={isDisabled}
                    label="Area/Locality"
                    labelClass="lblz"
                    errorclass="text-danger errorfont mh-18"
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <PincodeBox
                    tabIndex="105"
                    mainclass=" pt-2"
                    classes="pincode"
                    placeholder=""
                    inputclass="addinputs inpzz add-inp"
                    type="number"
                    name="pin"
                    stateName="state"
                    cityName="city"
                    disabled={isDisabled}
                    label="Pincode"
                    labelClass="lblz"
                    errorclass="text-danger errorfont pl-3"
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    tabIndex="106"
                    classes="material-textfield mr-2"
                    mainClass=" pt-2"
                    placeholder=""
                    inputClass="addinput inpzz add-inp"
                    type="text"
                    name="city"
                    label="City"
                    maxLength={50}
                    disabled={true}
                    labelClass="lblz"
                    errorclass="text-danger errorfont pl-3"
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    tabIndex="107"
                    classes="material-textfield mr-2"
                    mainClass="s pt-2"
                    placeholder=""
                    inputClass="addinput inpzz add-inp"
                    type="text"
                    name="state"
                    label="State"
                    maxLength={50}
                    disabled={true}
                    labelClass="lblz"
                    errorclass="text-danger errorfont mh-18"
                  />
                </div>
              </div>
              {/* </div> */}
              {apiMessage ? (
                <div className="row justify-content-center align-items-center">
                  <div className="text-danger text-center">
                    <label id="txterror" name="txterror">
                      {apiMessage}
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="alert alert-success" role="alert" style={{ fontSize: "13px" }}>
                {strings.current_address_note}
              </div>
              <div className="col-14 row justify-content-end align-items-center pt-2 mr-0">
                <Button
                  type="reset"
                  classes1="cancelbtn mt-0"
                  click={onCancel}
                  label="Cancel"
                />
                <Button
                  type="submit"
                  classes1="sbtn mt-0"
                  tabIndex="108"
                  label="Submit"
                />
              </div>
            </Form>
          </div>
        </div>
      </AlertBox>
    </div>
  );
};

export { Address };
