const options = {
  GENDER: ["Male", "Female", "Other"],
  MARITAL_STATUS: ["Single", "Married", "Others"],
  NATIONALITY: ["Indian", "Others"],
  ANY_ACTIONS_LAST_3YEARS: ["Yes", "No"],
  REGISTERED_OTHER_BROKER: ["Yes", "No"],
  CHOOSE_DEPOSITORY: ["CDSL", "NSDL"],
  RECEIVE_MANDATORY_DOCUMENTS: ["Electronic", "Physical"],
  WISH_AVAIL_INTERNET_FACIL: ["Yes", "No"],
  ARE_YOU_PEP: ["Yes", "No", "Related"],
  ACC_TYPE: ["Savings", "Current", "Others"],
  ADD_NOMINEE: ["Yes", "No"],
  ADD_NOMINEE_RECOMMENDED: ["Yes (Recommended)", "No"],
  MF_OPTIONS: [
    { label: "Without Demat", value: "P", helperText: "For investors making frequent purchases, switches & opting for SWP & STP options." },
    { label: "With Demat", value: "D", helperText: "For investors to use mutual funds as margin, pledge or cross-check holdings. Attracts nominal demat charges." },
  ],
  HOLDINGS_OPTIONS: [
    "Re-materialise",
    "Partly re-materialise and partly transfer.",
    "Transfer to another account",
  ],
};

export default options;
