import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import DropDown from "../Component/DropDown";
import RadioButtonGroup from "../Component/RadioButtonGroup";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import CheckBoxGroup from "../Component/CheckBox";
import CheckBoxOptions from "../Common/CheckBoxOptions";
import { MasterService } from "../Services/MasterService";
import { PATH } from "../Common/Path";
import { getCurrentStep } from "../Common/utils";

const PersonalDetailsOthers = (props) => {
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
    setHasChanges,
  } = useContext(AppContext);
  const [errors, setErrors] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const history = useHistory();
  const [visibleSection, setVisibleSection] = useState(false);
  const [formData, setFormData] = useState({
    broker: [],
    nationality: [RadioGroupOptions.NATIONALITY[0].checked],
  });
  const [tradingExp, setTradingExp] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const currentRef = useRef();

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    setFormData((formData) => ({
      ...formData,
      tadePattern: CheckBoxOptions.TRADINGPATTERN[0],
    }));
    Promise.all([
      MasterService.getDropdown("trading_experience"),
      LeadService.fillPersonalDetails("other"),
    ])
      .then((datas) => {
        if (datas[0] && datas[0].trading_experience) {
          setTradingExp(datas[0].trading_experience);
          const def = datas[0].trading_experience.find(
            (d) => d.is_default === true
          );
          setFormData((formData) => ({
            ...formData,
            trading_experience: def
              ? def.value
              : datas[0].trading_experience[0].value,
          }));
        }

        if (datas[1]) {
          setCurrentStep(getCurrentStep(datas[1].current_step));
          let data = datas[1];
          if (data.success) {
            let formData = {};
            if (data.trading_experience) {
              formData.trading_experience = data.trading_experience;
            }
            let broker = [];
            if (data.algo_pattern) {
              broker.push("algo_pattern");
            }
            if (data.intraday_pattern) {
              broker.push("intraday_pattern");
            }
            if (data.positional_pattern) {
              broker.push("positional_pattern");
            }
            if (data.investment_pattern) {
              broker.push("investment_pattern");
            }
            formData.broker = broker;
            formData.pep = data.politically_exposed;
            let depository = null;
            switch (data.depository) {
              case "CDSL":
                depository = "CDSL";
                break;
              case "NSDL":
                depository = "NSDL";
                break;
              default:
                break;
            }
            formData.depository = depository;
            formData.nationality =
              data.nationality === "1"
                ? RadioGroupOptions.NATIONALITY[0]
                : RadioGroupOptions.NATIONALITY[1];
            formData.regBroker = data.broker_registered
              ? RadioGroupOptions.REGISTERED_OTHER_BROKER[0]
              : RadioGroupOptions.REGISTERED_OTHER_BROKER[1];
            formData.name = data.broker_name;
            setFormData((fd) => ({ ...fd, ...formData }));
            setDisabledFields(data.disabled_fields || []);
          }
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
    currentRef.current.focus();
  }, [true]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  /**
   * validation for final submit
   */

  const mandatoryValidationSubmit = () => {
    let isValid = true;
    if (!formData.pep) {
      setErrors((oldErrors) => ({ ...oldErrors, pep: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, pep: false }));
    }
    if (!formData.depository) {
      setErrors((oldErrors) => ({ ...oldErrors, depository: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, depository: false }));
    }
    if (!formData.regBroker) {
      setErrors((oldErrors) => ({ ...oldErrors, regBroker: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, regBroker: false }));
    }

    if (!formData.nationality) {
      setErrors((oldErrors) => ({ ...oldErrors, nationality: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, nationality: false }));
    }
    return isValid;
  };

  /**
   * validation and submit data
   */
  const aboutYouNextClick = () => {
    if (mandatoryValidationSubmit()) {
      setLoading(true);

      let pep = "";
      switch (formData.pep) {
        case "Yes":
          pep = "Yes";
          break;
        case "No":
          pep = "No";
          break;
        case "Related":
          pep = "Related";
          break;
        default:
          break;
      }

      let nationality = "";
      switch (formData.nationality) {
        case "Indian":
          nationality = "1";
          break;
        case "Others":
          nationality = "0";
          break;
        default:
          break;
      }

      let depository = "";
      switch (formData.depository) {
        case "CDSL":
          depository = "CDSL";
          break;
        case "NSDL":
          depository = "NSDL";
          break;
        default:
          break;
      }
      let body = {
        type: "other",
        details: {
          trading_experience: formData.trading_experience,
          algo_pattern: formData.broker.includes(
            CheckBoxOptions.TRADINGPATTERN.find((p) => p.label === "Algo").value
          ),
          intraday_pattern: formData.broker.includes(
            CheckBoxOptions.TRADINGPATTERN.find((p) => p.label === "Intraday")
              .value
          ),
          positional_pattern: formData.broker.includes(
            CheckBoxOptions.TRADINGPATTERN.find((p) => p.label === "Positional")
              .value
          ),
          investment_pattern: formData.broker.includes(
            CheckBoxOptions.TRADINGPATTERN.find((p) => p.label === "Investment")
              .value
          ),
          politically_exposed: pep,
          nationality: parseInt(nationality),
          depository: depository,
          broker_registered:
            formData.regBroker === RadioGroupOptions.REGISTERED_OTHER_BROKER[0],
          broker_name: formData.name || null,
        },
      };
      setApiMessage("");
      LeadService.submitOtherDetails(body)
        .then((data) => {
          if (data.success) {
            history.push(PATH.TERMSANSCONDITION_PAGE);
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * previous button
   */
  const aboutYouPrevClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(PATH.PERSONALNOMINEE_PAGE);
      });
    } else {
      history.push(PATH.PERSONALNOMINEE_PAGE);
    }
  };
  /**
   * checkbox onchange function
   */
  const handleCheckBoxChange = (type) => (event) => {
    let selection = formData[type];
    if (event.target.checked) {
      selection.push(event.target.value);
      //console.log(selection.length);
    } else {
      selection = selection.filter((s) => s !== event.target.value);
      //console.log(selection.length,"else");
    }
    setFormData((oldData) => ({ ...oldData, [type]: selection }));
    setApiMessage("");
    setHasChanges(true);
  };
  /**
   * input onchange(this function saves the state of the form )
   * @param {*} event
   */
  const handleChange = (event) => {
    //event.persist();
    if (event.target.name === "name") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, name: false }));
    }
    if (event.target.name === "depository") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, depository: false }));
    }
    if (event.target.name === "pep") {
      setFormData((oldData) => ({ ...oldData, pep: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, pep: false }));
    }
    if (event.target.name === "nationality") {
      setFormData((oldData) => ({
        ...oldData,
        nationality: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, nationality: false }));
    }
    if (event.target.name === "ddl_tradExp") {
      setFormData((oldData) => ({
        ...oldData,
        trading_experience: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, ddl_tradExp: false }));
    }

    if (event.target.name === "regBroker") {
      setFormData((oldData) => ({ ...oldData, regBroker: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, regBroker: false }));
      if (event.target.value === "Yes") {
        setVisibleSection(true);
        if (formData.name) {
          formData.name = formData.name.trim();
        }

        if (!formData.name) {
          setErrors((oldErrors) => ({ ...oldErrors, name: true }));
        } else {
          setErrors((oldErrors) => ({ ...oldErrors, name: false }));
        }

        // if (formData.clientCode) {
        //     formData.clientCode = formData.clientCode.trim();
        // }

        // if (!formData.clientCode) {
        //     setErrors(oldErrors => ({ ...oldErrors, clientCode: true }))
        // }
        // else {
        //     setErrors(oldErrors => ({ ...oldErrors, clientCode: false }))
        // }
      } else {
        setVisibleSection(false);
      }
    }
    setHasChanges(true);
    setApiMessage("");
  };
  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-2">{strings.personal3_head}</h5>
                </div>
                <DropDown
                  reference={currentRef}
                  tabIndex="1"
                  mainclass="col-md-6 pb-3"
                  label={strings.trading_expe}
                  classes="inuput-box "
                  type="form-control"
                  drId="tradeexpId"
                  selectid="ddl_tradExp"
                  disabled={
                    disabledFields.includes("trading_experience") || false
                  }
                  fieldValue={formData.trading_experience}
                  options={tradingExp}
                  // error={errors.ddl_tradExp}
                  // errorMessage={strings.mandatory}
                  // errorclass="text-danger errorfont mh-18"
                  onselectchange={handleChange}
                  initialValue={formData.trading_experience}
                />
                <CheckBoxGroup
                  tabIndex="2"
                  mainclass="col-md-6 pb-3 mb-3"
                  subclass="inuput-box radio-box"
                  label={strings.pattern_label}
                  labelclass="color-add"
                  htmlfor="nse"
                  name="broker"
                  options={CheckBoxOptions.TRADINGPATTERN}
                  error={errors.broker}
                  change={handleCheckBoxChange("broker")}
                  defaultValue={formData.broker}
                />

                <RadioButtonGroup
                  tabIndex="3"
                  mainclass="col-md-6 pb-3"
                  label={strings.PEP_label}
                  options={RadioGroupOptions.ARE_YOU_PEP}
                  name="pep"
                  onOptionChange={handleChange}
                  disabled={
                    disabledFields.includes("politically_exposed") || false
                  }
                  error={errors.pep}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  selected={formData.pep || undefined}
                />

                <RadioButtonGroup
                  tabIndex="4"
                  mainclass="col-md-6 pb-3 "
                  label={strings.nationality_label}
                  options={RadioGroupOptions.NATIONALITY}
                  name="nationality"
                  onOptionChange={handleChange}
                  error={errors.nationality}
                  errorMessage={strings.nationality}
                  disabled={disabledFields.includes("nationality") || false}
                  errorclass="text-danger errorfont  mh-18"
                  selected={formData.nationality || undefined}
                />

                <RadioButtonGroup
                  tabIndex="5"
                  mainclass="col-md-6 pb-3 "
                  label={strings.depository_label}
                  options={RadioGroupOptions.CHOOSE_DEPOSITORY}
                  name="depository"
                  onOptionChange={handleChange}
                  error={errors.depository}
                  errorMessage={strings.mandatory}
                  disabled={disabledFields.includes("depository") || false}
                  errorclass="text-danger errorfont mh-18"
                  selected={formData.depository || undefined}
                />

                <RadioButtonGroup
                  tabIndex="6"
                  mainclass="col-md-6 pb-3 "
                  label={strings.register_broker_label}
                  options={RadioGroupOptions.REGISTERED_OTHER_BROKER}
                  name="regBroker"
                  onOptionChange={handleChange}
                  error={errors.regBroker}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  disabled={
                    disabledFields.includes("broker_registered") || false
                  }
                  selected={formData.regBroker || undefined}
                />
                {visibleSection && (
                  <React.Fragment>
                    <TextBox
                      tabIndex="7"
                      mainclass="col-md-6 pb-3"
                      placehold={strings.broker_name}
                      classes="inuput-box"
                      label={strings.broker_name}
                      inputclass="mb-0"
                      type="text"
                      textId="name"
                      fieldValue={formData.name}
                      disabled={disabledFields.includes("broker_name") || false}
                      onTextChange={handleChange}
                      error={errors.name}
                      errorclass=""
                      errorMessage=""
                    />
                  </React.Fragment>
                )}

                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      {" "}
                      <div className=" inp api-errormessage text-danger text-center">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      <Button
                        classes="inp-back"
                        label={strings.previous}
                        click={aboutYouPrevClick}
                      />
                    </div>
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={aboutYouNextClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PersonalDetailsOthers;
