import Config from "../Common/Config";
import { OTP_TYPES } from "../Common/Constants";
import { RestService } from "./RestService";

export const LeadService = {
  //mobile email
  requestOTP: async function (
    type = OTP_TYPES.SMS,
    mobile = null,
    email = null,
    referral_id = null
  ) {
    return RestService.get(
      `${Config.request_otp_url}?type=${type}${
        mobile ? `&mobile=${mobile}` : ""
      }${email ? `&email=${email}` : ""}${
        referral_id ? `&referral_id=${referral_id}` : ""
      }`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  validateOTP: async function (
    type = OTP_TYPES.SMS,
    mobile = null,
    email = null,
    otp = null,
    referral_id = null,
    created_by = null
  ) {
    return RestService.get(
      `${Config.validate_otp_url}?type=${type}${
        mobile ? `&mobile=${mobile}` : ""
      }${email ? `&email=${email}` : ""}${otp ? `&otp=${otp}` : ""}${
        referral_id ? `&referral_id=${referral_id}` : ""
      }`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  requestSetPassword: async function (body) {
    return RestService.post(Config.password_set_url, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  validateGoogle: async function (body) {
    return RestService.post(Config.validateGoogle, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  //pan
  validatePAN: async function (body) {
    return RestService.post(Config.validatePAN, {}, body).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  getLeadPanDetails: async function () {
    return RestService.get(Config.get_lead_pan_details, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  getRefetchPanDetails: async function () {
    return RestService.get(Config.refetch_pan_name, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  submitPANName: async function (body) {
    return RestService.post(Config.submitPANName, {}, body).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  //personal details
  submitBasicDetails: async function (body) {
    return RestService.post(Config.submit_basic_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  submitOtherDetails: async function (body) {
    return RestService.post(Config.submit_other_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  submitAboutYouAddress: async function (body) {
    return RestService.post(Config.submit_address_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  fillPersonalDetails: async function (type = null) {
    return RestService.get(
      `${Config.fillPersonalDetails}?type=${type}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  //relation
  submit_relation: async function (body) {
    return RestService.post(Config.submit_relation, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  //uploadproof
  getLeadProofs: async function () {
    return RestService.get(Config.upload_proofs, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  getLeadUploadStatus: async function () {
    return RestService.post(Config.upload_step_status_check, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  uploadProofs: async function (body) {
    return RestService.postFormData(Config.upload_proofs, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  getUploadProofList: async function (body) {
    return RestService.get(Config.get_upload_proof_list, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  //Digilock
  submitDigilockerData: async function (body) {
    return RestService.get(Config.submitdigilockerdata, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  postDigioResponse: async function (body) {
    return RestService.post(Config.submitdigilockerdata, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  submitManualDigilocker: async function (body) {
    return RestService.post(Config.submit_digilocker_manual, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  //payment
  getPaymentAmount: async function (body) {
    return RestService.get(Config.getPaymentAmount, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  submitPayment: async function (body) {
    return RestService.post(Config.create_payment_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  requestPayment: async function (coupon) {
    return RestService.get(
      `${Config.create_payment_details}?${coupon ? `coupon=${coupon}` : ""}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  validatePromoCode: async function (coupon) {
    return RestService.get(
      `${Config.validatepromocode}?coupon=${coupon}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  //photo video ipv
  getImageOrVideo: async function () {
    return RestService.get(`${Config.getImageOrVideo}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  createVideoRequest: async function () {
    return RestService.get(`${Config.video_request}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  updateVideoDetails: async function (body) {
    return RestService.post(`${Config.video_request}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  createPhotoRequest: async function () {
    return RestService.get(`${Config.photo_request}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  resumeApplicationLink: async function (step) {
    return RestService.get(
      `${Config.resume_application}${step ? `?step=${step}` : ""}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  updatePhotoDetails: async function (body) {
    return RestService.post(`${Config.photo_request}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  updateIPVStep: async function () {
    return RestService.post(Config.update_ipv_steps, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  //thank you
  getThankyou: async function () {
    return RestService.get(`${Config.get_Thankyou}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  //esign
  requestESign: async function () {
    return RestService.get(`${Config.get_esign_permission}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  postESign: async function (body) {
    return RestService.post(`${Config.get_esign_permission}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  getESignDetails: async function () {
    return RestService.get(`${Config.get_esign_details}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  getManualRequest: async function () {
    return RestService.get(`${Config.get_esign_manual}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  //segment
  getPreviousSegments: async function () {
    return RestService.get(`${Config.get_previous_segments}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  submitSegments: async function (body) {
    return RestService.post(Config.submit_segments, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  // T & C
  getTermsConditions: async function (type = null) {
    return RestService.get(
      `${Config.get_termsconditions}?type=${type}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  postTermsConditions: async function (body) {
    return RestService.post(`${Config.post_termsconditions}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 400 ||
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  //signup
  SubmitEmailandPassword: async function (body) {
    return RestService.post(Config.signup, {}, body).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  // SubmitEmailandPassword:async function (body) {
  //   return RestService.get(Config.submitemailandpassword, {}, body)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         return response.json();
  //       } else if (
  //         response.status === 404 ||
  //         response.status === 409 ||
  //         response.status === 400 ||
  //         response.status === 403 ||
  //         response.status === 500
  //       ) {
  //         return response.json();
  //       }
  //     });
  // },
  //bank
  submit_about_bank_details: async function (body) {
    return RestService.post(Config.submit_about_bank_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  getReversePennyDrop: async function () {
    return RestService.get(Config.get_reverse_penny_drop, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  postReversePennyDrop: async function (body) {
    return RestService.post(Config.get_reverse_penny_drop, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  getRekycReversePennyDrop: async function () {
    return RestService.get(Config.rekyc_reverse_penny_drop, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  postRekycReversePennyDrop: async function (body) {
    return RestService.post(Config.rekyc_reverse_penny_drop, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  getBankDetails: async function () {
    return RestService.get(Config.get_about_bank_details, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  verifyBankAccount: async function (body) {
    return RestService.post(`${Config.verifyBankAccount}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  //get lead status
  getEndLeadStatus: async function () {
    return RestService.get(Config.get_end_lead_status, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  getLeadCurrentStep: async function () {
    return RestService.get(Config.get_lead_current_step, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },

  //--------------------------------------------------------------------------------------------------------

  getLeadCount: async function (status = null, branch = null, filter = "") {
    return RestService.get(
      `${Config.list_leads_url}?count=true&filter=${filter}${
        status ? `&status=${status}` : ""
      }${branch ? `&branch=${branch}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getLeadList: async function (
    status = null,
    branch = null,
    filter = "",
    sort = null,
    direction = null,
    start = 0,
    offset = 20
  ) {
    return RestService.get(
      `${Config.list_leads_url}?filter=${filter}${
        status ? `&status=${status}` : ""
      }${branch ? `&branch=${branch}` : ""}&from=${start}&offset=${offset}${
        sort ? `&sort=${sort}` : ""
      }${direction ? `&direction=${direction}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  searchLead: async function (filter = "", start = 0, offset = 20) {
    return RestService.get(
      `${Config.search_url}?filter=${filter}&from=${start}&offset=${offset}`,
      {}
    ).then((response) => response.json());
  },
  getLeadById: async function (id = "", onlyForm = false) {
    return RestService.get(
      `${Config.get_lead_detail_url.replace("{id}", id)}?onlyForm=${onlyForm}`,
      {}
    ).then((response) => response.json());
  },
  removeLead: async function (id, headers = {}) {
    return RestService.delete(
      Config.get_lead_detail_url.replace("{id}", id),
      headers
    ).then((response) => response.json());
  },
  getSubSteps: async function (step = "") {
    return RestService.get(
      Config.get_sub_steps_url.replace("{step}", step),
      {}
    ).then((response) => response.json());
  },
  createLead: async function (body, headers = {}) {
    return RestService.postFormData(Config.save_lead_url, headers, body).then(
      (response) => response.json()
    );
  },
  saveStep: async function (lead, body, headers = {}) {
    return RestService.post(
      Config.save_step_url.replace("{lead}", lead),
      headers,
      body
    ).then((response) => response.json());
  },
  updateLead: async function (lead, body, headers = {}) {
    return RestService.post(
      Config.update_lead_url.replace("{lead}", lead),
      headers,
      body
    );
  },
  resendEmail: async function (lead, step, headers = {}) {
    return RestService.get(
      Config.resend_email_url.replace("{lead}", lead).replace("{step}", step),
      headers
    ).then((response) => response.json());
  },
  getLeadStepDocuments: async function (lead = null, step = null) {
    return RestService.get(
      `${Config.lead_step_documents_url.replace("{lead}", lead)}${
        step ? `?step=${step}` : ""
      }`,
      {}
    ).then((response) => response.json());
  },
  addLeadStepDocument: async function (lead, step, body, headers = {}) {
    return RestService.postFormData(
      Config.add_lead_step_documents_url
        .replace("{lead}", lead)
        .replace("{step}", step),
      headers,
      body
    ).then((response) => response.json());
  },
  removeDocument: async function (id, lead, step, headers = {}) {
    return RestService.delete(
      Config.remove_lead_step_documents_url
        .replace("{id}", id)
        .replace("{lead}", lead)
        .replace("{step}", step),
      headers
    );
  },

  //REKYC
  rekycRequestOTP: async function (id = null) {
    return RestService.get(
      Config.rekyc_request_otp.replace("{id}", id),
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycValidateOTP: async function (id = null, otp = null) {
    return RestService.get(
      `${Config.rekyc_validate_otp_url}?id=${id}&otp=${otp}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  getRekycSummary: async function () {
    return RestService.get(
      `${Config.rekyc_lead_summary}?nominee=true&segment=true&bank=true`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  getRekycPaymentSummary: async function (body) {
    return RestService.post(Config.rekyc_lead_payment_summary, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  updateSegments: async function (body) {
    return RestService.post(Config.rekyc_update_lead_segments, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  updateRekycLeadDetails: async function (body) {
    return RestService.post(Config.rekyc_lead_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycRevertAction: async function (type) {
    return RestService.post(
      `${Config.rekyc_revert_action}?type=${type}`,
      {},
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycSectionRequestOTP: async function (type = OTP_TYPES.SMS, id = null) {
    return RestService.get(
      `${Config.rekyc_section_request_otp}?type=${type}&id=${id}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  rekycSectionValidateOTP: async function (
    type = OTP_TYPES.SMS,
    id = null,
    otp = null,
    relationship = null
  ) {
    return RestService.post(
      `${Config.rekyc_section_validate_otp}?type=${type}&id=${id}&otp=${otp}${
        relationship ? `&relationship=${relationship}` : ""
      }`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycSubmitDigilockerData: async function (body) {
    return RestService.get(Config.rekyc_digilocker_data, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycPostDigioResponse: async function (body) {
    return RestService.post(Config.rekyc_digilocker_data, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycSubmitAddressManual: async function (body) {
    return RestService.post(Config.rekyc_address_manual, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycVerifyBankAccount: async function (body) {
    return RestService.post(
      `${Config.rekyc_verify_bank_details}`,
      {},
      body
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycAddBankDetails: async function (body) {
    return RestService.post(Config.rekyc_add_new_bank_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycMarkBankAsPrimary: async function (body) {
    return RestService.post(Config.rekyc_mark_bank_as_primary, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetIpvDetails: async function () {
    return RestService.get(`${Config.rekyc_get_ipv_details}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycResumeApplicationLink: async function () {
    return RestService.get(`${Config.rekyc_resume_application}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycVideoRequest: async function () {
    return RestService.get(`${Config.rekyc_video_request}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycUpdateVideoDetails: async function (body) {
    return RestService.post(`${Config.rekyc_video_request}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycPhotoRequest: async function () {
    return RestService.get(`${Config.rekyc_photo_request}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycUpdatePhotoDetails: async function (body) {
    return RestService.post(`${Config.rekyc_photo_request}`, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycUpdateIPVDetails: async function () {
    return RestService.post(Config.rekyc_update_ipv_details, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycNomineeDetails: async function (body) {
    return RestService.post(Config.rekyc_nominee_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetUploadProofList: async function (body) {
    return RestService.get(Config.rekyc_get_upload_proof_list, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetLeadProofs: async function () {
    return RestService.get(Config.rekyc_upload_proofs, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycUploadProofs: async function (body) {
    return RestService.postFormData(Config.rekyc_upload_proofs, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetLeadUploadStatus: async function () {
    return RestService.post(Config.rekyc_upload_step_status, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetESignDetails: async function () {
    return RestService.get(`${Config.rekyc_get_esign_details}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycPostESign: async function (body) {
    return RestService.post(
      `${Config.rekyc_get_esign_permission}`,
      {},
      body
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  rekycRequestESign: async function () {
    return RestService.get(`${Config.rekyc_get_esign_permission}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetManualRequest: async function () {
    return RestService.get(`${Config.rekyc_get_esign_manual}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetLeadStatus: async function () {
    return RestService.get(`${Config.rekyc_get_lead_status}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  rekycGetStepDetails: async function (step_name = null) {
    return RestService.get(
      `${Config.rekyc_get_step_details}?step_name=${step_name}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },

  // Closure
  closureRequestOTP: async function (id = null) {
    return RestService.get(
      Config.closure_request_otp.replace("{id}", id),
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  closureValidateOTP: async function (id = null, otp = null) {
    return RestService.get(
      `${Config.closure_validate_otp_url}?id=${id}&otp=${otp}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  getClosureSummary: async function () {
    return RestService.get(Config.closure_lead_summary, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  updateClosureLeadDetails: async function (body) {
    return RestService.post(Config.closure_lead_details, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureGetUploadProofList: async function (body) {
    return RestService.get(Config.closure_get_upload_proof_list, {}, body).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureGetLeadProofs: async function () {
    return RestService.get(Config.closure_upload_proofs, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureUploadProofs: async function (body) {
    return RestService.postFormData(
      Config.closure_upload_proofs,
      {},
      body
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  closureGetLeadUploadStatus: async function () {
    return RestService.post(Config.closure_upload_step_status, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureGetStepDetails: async function (step_name = null) {
    return RestService.get(
      `${Config.closure_get_step_details}?step_name=${step_name}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  closureGetESignDetails: async function () {
    return RestService.get(`${Config.closure_get_esign_details}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closurePostESign: async function (body) {
    return RestService.post(
      `${Config.closure_get_esign_permission}`,
      {},
      body
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        return response.json();
      }
    });
  },
  closureRequestESign: async function () {
    return RestService.get(`${Config.closure_get_esign_permission}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureGetManualRequest: async function () {
    return RestService.get(`${Config.closure_get_esign_manual}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
  closureGetLeadStatus: async function () {
    return RestService.get(`${Config.closure_get_lead_status}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403 ||
          response.status === 500
        ) {
          return response.json();
        }
      }
    );
  },
};
