import React from "react";
import { Radio } from "./Radio";
import PropTypes from "prop-types";
import { useForm } from ".";
import { flatten } from "lodash";

const RadioGroup = (props) => {
  const {
    name,
    disabled: disabledProp,
    errorClass = "text-danger errorfont mh-18",
    reference,
    tabIndex,
    readOnly: isReadOnly,
    hidden = false,
    required = false,
    options = [],
    inputClass,
    labelClass,
    mainLabel,
    mainLabelClass,
    index = -1,
    parentNode = "",
    onChange,
    mainClass = "row",
  } = props;
  const { errors, formIsDisabled, formIsReadOnly, formIsLoading } = useForm();
  const disabled = disabledProp || formIsDisabled;
  const readOnly = isReadOnly || formIsReadOnly || formIsLoading;

  const hasError = (errors) => {
    if (index !== -1) {
      return (
        errors &&
        errors[parentNode] &&
        errors[parentNode][index] &&
        errors[parentNode][index][name] &&
        errors[parentNode][index][name].message
      );
    }
    if (name && name.includes(".")) {
      const parts = flatten(
        name
          .split(".")
          .map((item) => item.split(/\[/).map((item) => item.replace("]", "")))
      );
      let error = errors;
      parts.forEach((part) => {
        error = error?.[part] || {};
      });
      return error ? error.message : undefined;
    }
    return errors && errors[name] ? errors[name].message : undefined;
  };

  const error = hasError(errors);
  return (
    <>
      <div className={mainClass} id={name}>
        {mainLabel ? (
          <label className={mainLabelClass}>{mainLabel}</label>
        ) : null}
        <div className="column ml-3">
          <div className="row">
            {options.map((option, i) => {
              return (
                <Radio
                  key={i}
                  tabIndex={tabIndex}
                  type="radio"
                  onChange={onChange}
                  id={`${name}-${option}`}
                  name={name}
                  label={option}
                  hidden={hidden}
                  readOnly={readOnly}
                  required={required}
                  inputClass={inputClass || "mslbl"}
                  labelClass={labelClass || "rlbl"}
                  disabled={disabled}
                  reference={reference}
                  errorClass={errorClass}
                  value={option}
                  showError={false}
                  index={index}
                  parentNode={parentNode}
                />
              );
            })}
          </div>
          <div className={errorClass}>{error}</div>
        </div>
      </div>
    </>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string,
  hidden: PropTypes.bool,
  step: PropTypes.string,
  min: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
  parentNode: PropTypes.string,
  mainClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  errorClass: PropTypes.string,
  tabIndex: PropTypes.string,
  options: PropTypes.array.isRequired,
};
export { RadioGroup };
