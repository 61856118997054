import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./routes";
import { AppContext } from "./Context/AppContext";
import { MasterService } from "./Services/MasterService";
import LoaderSpin from "./Component/Loader";
import { PATH } from "./Common/Path";
import "react-datepicker/dist/react-datepicker.css";
import AlertBox from "./Component/AlertBox";
import { LeadService } from "./Services/LeadService";
import { getCurrentStep, getPath } from "./Common/utils";

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

function App({ history, ...props }) {
  const {
    isLoading,
    token,
    setToken,
    isAlert,
    alertDialog,
    setCurrentStep,
    hasChanges,
  } = useContext(AppContext);

  window.onload = function () {
    initBeforeUnLoad(hasChanges);
  };
  useEffect(() => {
    initBeforeUnLoad(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    let currentPathname = undefined;
    let currentSearch = undefined;
    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        history.go(1);
      }
    });
  }, []);

  useEffect(() => {
    if (
      !token ||
      [
        PATH.MOBILE_PAGE,
        PATH.SIGNIN_PAGE,
        PATH.REKYC_SIGNIN_PAGE,
        PATH.CLOSURE_SIGNIN_PAGE,
      ].includes(props.location.pathname)
    ) {
      //
      MasterService.getGuestToken()
        .then((data) => {
          setToken(data.token);
          if (
            ![
              PATH.MOBILE_PAGE,
              PATH.SIGNIN_PAGE,
              PATH.REKYC_SIGNIN_PAGE,
            ].includes(props.location.pathname)
          ) {
            history.push(props.location.pathname);
          }
        })
        .catch((error) => console.error(error));
    } else {
      LeadService.getLeadCurrentStep()
        .then((data) => {
          if (data.success) {
            setCurrentStep(getCurrentStep(data.current_step));
            history.replace(getPath(data.current_step));
          } else {
            history.push(PATH.MOBILE_PAGE);
          }
        })
        .catch((error) => {
          console.error(error);
          history.push(PATH.MOBILE_PAGE);
        });
    }
  }, []);
  return (
    <div>
      <Routes />
      {isLoading && (
        <div className="loaderContainer" style={{ zIndex: 9999 }}>
          <LoaderSpin />
        </div>
      )}
      <div className="loaderContainer">
        <AlertBox
          show={isAlert}
          message={alertDialog.message}
          title={alertDialog.title}
          confirmButtonText={alertDialog.confirmButtonText}
          cancelButtonText={alertDialog.cancelButtonText}
          confirmButtonAction={alertDialog.confirmButtonAction}
          cancelButtonAction={alertDialog.cancelButtonAction}
        />
      </div>
    </div>
  );
}

export default withRouter(App);
