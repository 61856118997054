import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../../Common/String";
import Button from "../../Component/Button";
import { LeadService } from "../../Services/LeadService";
import { PATH } from "../../Common/Path";
import useScript from "../../useScript";
import queryString from "query-string";
import { getPath } from "../../Common/utils";
import LayoutWrapper from "../../Common/LayoutWrapper";

const Esign = (props) => {
  const {
    setLoading,
    setHasChanges,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
  } = useContext(AppContext);
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState("");
  const [isAdharEnabled, setIsAdharEnabled] = useState(false);
  const [isCourierEnabled, setIsCourierEnabled] = useState(false);
  const [step, setStep] = useState(null);

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    setApiMessage("");
    Promise.all([
      LeadService.rekycGetESignDetails(),
      LeadService.rekycGetStepDetails("ReKYCESign"),
    ])
      .then((data) => {
        if (data[0].success) {
          setIsAdharEnabled(data[0].show_esign);
          setIsCourierEnabled(data[0].show_manual);
        } else {
          setApiMessage(data[0].error);
        }
        if (data[1].success) {
          setStep(data[1].previous_step);
        } else {
          setStep(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const updateDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    LeadService.rekycPostESign(body)
      .then((data) => {
        if (data.success) {
          history.replace(getPath(data.next_step));
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  if (props.location.search) {
    let qString = queryString.parse(props.location.search);
    const status = qString.status || null;
    let digioId = null;
    if (status && status === "success") {
      digioId = qString.digio_doc_id;
    }
    if (digioId) {
      updateDetails({ digio_doc_id: digioId });
    }
  }

  /**
   * Esign Button Click
   */
  const onEsignClick = () => {
    setLoading(true);
    LeadService.rekycRequestESign()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updateDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            let url = data.redirect_url.split("&theme=", 1);
            window.open(
              `${url[0]}&redirect_url=${
                window.location.protocol + "//" + window.location.host
              }${PATH.REKYC_ESIGN_PAGE}&theme=${url[1]}`,
              "_self"
            );
          }
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Courier form Button click
   */
  const onCourierClick = () => {
    showConfirmDialog(
      strings.proceed_msg,
      undefined,
      () => {
        setLoading(true);
        setApiMessage("");
        LeadService.rekycGetManualRequest()
          .then((data) => {
            if (data.success) {
              window.open(data.file_url, "_blank");
              history.push(getPath(data.next_step));
            } else {
              setApiMessage(data.error);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setLoading(false));
        closeConfirmDialog();
      },
      strings.yes,
      strings.no
    );
  };

  /**
   * previous button
   */
  const previousClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(getPath(step));
      });
    } else {
      history.push(getPath(step));
    }
  };

  return (
    <LayoutWrapper>
      <div className="summarydiv">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 marg">
              <div className="forms ">
                <div className="row pb-2">
                  <div className="col-md-12 pb-4">
                    <h5 className="head4 pb-2">{strings.esignhead}</h5>
                  </div>
                  {isAdharEnabled && (
                    <div className="col-md-6 pt-2 pb-2">
                      <div className="esign-box text_left">
                        <h5 className="head5 pb-1">
                          {strings.aadhar} &amp; {strings.moblinked}
                        </h5>
                        <p>{strings.esignaadharmobotp}</p>
                        <a
                          className="e-sign"
                          style={{ textDecoration: "none" }}
                          onClick={onEsignClick}
                          href="# "
                        >
                          {strings.iwillsignin}
                        </a>
                      </div>
                    </div>
                  )}
                  {isCourierEnabled && (
                    <div className="col-md-6 pt-2 pb-2">
                      <div className="esign-box h-256 text_left">
                        <h5 className="head5 pb-1">
                          {strings.aadhar} &amp; {strings.aadharmobnotlink}
                        </h5>
                        <p className="">{strings.notaharcourier}</p>
                        <a
                          className="courier mt-7"
                          style={{ textDecoration: "none" }}
                          onClick={onCourierClick}
                          href="# "
                        >
                          {strings.iwillcourierform}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 pt-4">
                    <div className="alert alert-success text_left" role="alert">
                      {strings.esign_note}
                    </div>
                  </div>
                  <div className="col-md-12 pt-4">
                    <div className="row mh-42">
                      <div className="col-md-12 pt-2">
                        {" "}
                        <div className="inp api-errormessage text-danger text-center">
                          <label id="txterror" name="txterror">
                            {apiMessage}
                          </label>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 pt-4">
                        {step && (
                          <Button
                            classes="inp-back"
                            label={strings.previous}
                            click={previousClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
export default Esign;
