import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { PATH } from "../Common/Path";
import useScript from "../useScript";
import queryString from "query-string";
import { getCurrentStep, getPath } from "../Common/utils";
import AlertBox from "../Component/AlertBox";

const Esign = (props) => {
  const {
    setLoading,
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    setHasChanges,
    showConfirmDialog,
    closeConfirmDialog,
  } = useContext(AppContext);
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState("");
  const [isAdharEnabled, setIsAdharEnabled] = useState(false);
  const [isCourierEnabled, setIsCourierEnabled] = useState(false);
  const [isRejected, setIsReject] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    setApiMessage("");
    LeadService.getESignDetails()
      .then((data) => {
        setCurrentStep(getCurrentStep(data.current_step));
        setIsReject(data.rejected);
        if (data.success) {
          setIsAdharEnabled(data.show_esign);
          setIsCourierEnabled(data.show_manual);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  const updateDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    LeadService.postESign(body)
      .then((data) => {
        if (data.success) {
          history.replace(getPath(data.next_step));
          setCurrentStep(getCurrentStep(data.next_step));
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  if (props.location.search) {
    let qString = queryString.parse(props.location.search);
    const status = qString.status || null;
    let digioId = null;
    if (status && status === "success") {
      digioId = qString.digio_doc_id;
    }
    if (digioId) {
      updateDetails({ digio_doc_id: digioId });
    }
  }

  /**
   * Esign Button Click
   */
  const onEsignClick = () => {
    setLoading(true);
    LeadService.requestESign()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updateDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            let url = data.redirect_url.split("&theme=", 1);
            window.open(
              `${url[0]}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.ESIGN_PAGE}&theme=${url[1]}`,
              "_self"
            );
          }
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Courier form Button click
   */
  const onCourierClick = () => {
    showConfirmDialog(
      strings.proceed_msg,
      undefined,
      () => {
        setLoading(true);
        setApiMessage("");
        LeadService.getManualRequest()
          .then((data) => {
            if (data.success) {
              window.open(data.file_url, "_blank");
              history.push(getPath(data.next_step));
              //(data.file_url)
            } else {
              setApiMessage(data.error);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setLoading(false));
        closeConfirmDialog();
      },
    );
  };

  /**
   * previous button
   */
  const previousClick = () => {
    history.push(PATH.UPLOADPROOFS_PAGE);
  };

  return (
    <div className="inner-section">
      <div className="details">
        <AlertBox
          show={showAlert}
          showCancelIcon
          className="w-10"
          title={"Attention!"}
          message={<div className="text-justify -mb-3">
            {strings.esign_alert}
          </div>}
          confirmButtonText={strings.continue}
          confirmButtonAction={() => setShowAlert(false)}
          cancelButtonAction={() => setShowAlert(false)}
        />
        <div className="container-fluid">
          <div className="row">
            <Header2 show={!isRejected} />
            <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
              <div className="row">
                <div className="col-md-12 pb-4">
                  <h5 className="pb-2">{strings.esignhead}</h5>
                </div>
                {isAdharEnabled && (
                  <div className="col-md-6 pt-2 pb-2">
                    <div className="box-ad">
                      <h5>
                        {strings.aadhar} &amp; {strings.moblinked}
                      </h5>
                      <p>{strings.esignaadharmobotp}</p>
                      <a
                        className="e-sign"
                        style={{ textDecoration: "none" }}
                        onClick={onEsignClick}
                        href="# "
                      >
                        {strings.iwillsignin}
                      </a>
                    </div>
                  </div>
                )}
                {isCourierEnabled && (
                  <div className="col-md-6 pt-2 pb-2">
                    <div className="box-ad">
                      <h5>
                        {strings.aadhar} &amp; {strings.aadharmobnotlink}
                      </h5>
                      <p>{strings.notaharcourier}</p>
                      <a
                        className="courier"
                        style={{ textDecoration: "none" }}
                        onClick={onCourierClick}
                        href="# "
                      >
                        {strings.iwillcourierform}
                      </a>
                    </div>
                  </div>
                )}
                {/* <div className="col-md-12 pt-4">
                  <div className="alert alert-success" role="alert">
                    {strings.esign_note}
                  </div>
                </div> */}
                <div className="col-md-12 pt-4">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      {" "}
                      <div className=" inp api-errormessage text-danger text-center">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      {!isRejected && (
                        <Button
                          classes="inp-back"
                          label={strings.previous}
                          click={previousClick}
                        />
                      )}
                    </div>
                    <div className="col-6 pt-3 text-right">
                      {/* <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.next}
                        click={nextClick}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Esign;
