const host =
  process.env.NODE_ENV === "production"
    ? "https://api.kyc.acumengroup.in"
    : "https://ekycdev.acumengroup.in/acumen";

/**.Following are the API Url */
const Config = {
  //mobile & email
  password_set_url: `${host}/create/password`,
  request_otp_url: `${host}/request/otp`,
  validate_otp_url: `${host}/validate/otp`,
  validateGoogle: `${host}/google/verified`,
  // request_Otp_SMS_Wap: `${host}/authenticate`,
  //pan
  validate_pan_url: `${host}/validate/pan`,
  submitPANName: `${host}/submit/pan`,
  validatePAN: `${host}/validate/pan`,
  get_lead_pan_details: `${host}/lead/pan/details`,
  fetch_pan_name_url: `${host}/pan/name`,
  refetch_pan_name: `${host}/refetch/pan/name`,
  //personal details
  submit_basic_details: `${host}/lead/personal/details`,
  submit_other_details: `${host}/lead/personal/details`,
  submit_address_details: `${host}/lead/personal/details`,
  fillPersonalDetails: `${host}/lead/personal/details`,
  pincode_url: `${host}/pincode`,
  city_url: `${host}/city`,
  //submit_relation:`${host}/submit/relation`,
  guest_token: `${host}/guest/token`,
  validate_token: `${host}/validate/token`,
  get_dropdown: `${host}/dropdown/options`,
  upload_proofs: `${host}/lead/proofs/details`,
  upload_step_status_check: `${host}/lead/proofs/step`,
  //digilocker
  submitdigilockerdata: `${host}/lead/digilocker`,
  submit_digilocker_manual: `${host}/lead/mobile/notlinked`,
  // photo video
  getImageOrVideo: `${host}/lead/ipv/details`,
  video_request: `${host}/lead/video/details`,
  photo_request: `${host}/lead/photo/details`,
  resume_application: `${host}/send/resume/link`,
  update_ipv_steps: `${host}/lead/ipv/details`,
  //thank you
  get_Thankyou: `${host}/lead/name`,
  //ESIGN
  get_esign_permission: `${host}/lead/esign`,
  get_esign_details: `${host}/lead/esign/details`,
  get_esign_manual: `${host}/lead/manual`,
  //segment
  submit_segments: `${host}/lead/segment/details`,
  get_previous_segments: `${host}/lead/segment/details`,
  get_segments: `${host}/segments`,
  get_brokerage: `${host}/brokerage`,
  //payment
  getPaymentAmount: `${host}/lead/payment/summary`,
  getdaymentdmount: `${host}/paymentamounts`,
  validatepromocode: `${host}/apply/lead/coupon`,
  create_payment_details: `${host}/lead/payment/details`,
  //terms & condition
  get_termsconditions: `${host}/lead/personal/details`,
  terms_conditions: `${host}/terms`,
  post_termsconditions: `${host}/lead/personal/details`,
  //relation
  submit_relation: `${host}/lead/relationship`,
  //signIn
  submitemailandpassword: `${host}/signup/emailandpassword`,
  signup: `${host}/lead/signin`,
  //rekyc
  rekyc_request_otp: `${host}/rekyc/request/signin/otp?id={id}`,
  rekyc_validate_otp_url: `${host}/rekyc/validate/signin/otp`,
  rekyc_lead_summary: `${host}/rekyc/lead/summary`,
  rekyc_lead_payment_summary: `${host}/rekyc/lead/payment/summary`,
  rekyc_update_lead_segments: `${host}/rekyc/lead/segment`,
  rekyc_lead_details: `${host}/rekyc/lead/details`,
  rekyc_revert_action: `${host}/rekyc/revert/action`,
  rekyc_section_request_otp: `${host}/rekyc/request/update/otp`,
  rekyc_section_validate_otp: `${host}/rekyc/validate/update/otp`,
  rekyc_digilocker_data: `${host}/rekyc/lead/digilocker`,
  rekyc_address_manual: `${host}/rekyc/lead/address`,
  rekyc_verify_bank_details: `${host}/rekyc/verify/bank/details`,
  rekyc_add_new_bank_details: `${host}/rekyc/lead/bank/details`,
  rekyc_mark_bank_as_primary: `${host}/rekyc/mark/bank/primary`,

  rekyc_get_ipv_details: `${host}/rekyc/lead/ipv/details`,
  rekyc_video_request: `${host}/rekyc/lead/video/details`,
  rekyc_photo_request: `${host}/rekyc/lead/photo/details`,
  rekyc_resume_application: `${host}/rekyc/send/resume/link`,
  rekyc_update_ipv_details: `${host}/rekyc/lead/ipv/details`,
  rekyc_nominee_details: `${host}/rekyc/lead/nominee`,
  rekyc_get_upload_proof_list: `${host}/rekyc/lead/required/proofs`,
  rekyc_upload_proofs: `${host}/rekyc/lead/proofs/details`,
  rekyc_upload_step_status: `${host}/rekyc/lead/proofs/step`,
  rekyc_get_esign_details: `${host}/rekyc/lead/esign/details`,
  rekyc_get_esign_permission: `${host}/rekyc/lead/esign`,
  rekyc_get_esign_manual: `${host}/rekyc/lead/manual`,
  rekyc_get_lead_status: `${host}/rekyc/lead/status`,
  rekyc_get_step_details: `${host}/rekyc/lead/step/details`,
  rekyc_reverse_penny_drop: `${host}/rekyc/lead/rpd`,

  // Closure
  closure_request_otp: `${host}/closure/request/signin/otp?id={id}`,
  closure_validate_otp_url: `${host}/closure/validate/signin/otp`,
  closure_lead_summary: `${host}/closure/lead/summary`,
  closure_lead_details: `${host}/closure/lead/details`,
  closure_get_upload_proof_list: `${host}/closure/lead/required/proofs`,
  closure_upload_proofs: `${host}/closure/lead/proofs/details`,
  closure_upload_step_status: `${host}/closure/lead/proofs/step`,
  closure_get_step_details: `${host}/closure/lead/step/details`,
  closure_get_esign_details: `${host}/closure/lead/esign/details`,
  closure_get_esign_permission: `${host}/closure/lead/esign`,
  closure_get_esign_manual: `${host}/closure/lead/manual`,
  closure_get_lead_status: `${host}/closure/lead/status`,

  //bank details
  submit_about_bank_details: `${host}/lead/bank/details`,
  get_about_bank_details: `${host}/lead/bank/details`,
  verifyBankAccount: `${host}/verify/bank/details`,
  get_upload_proof_list: `${host}/lead/required/proofs`,
  get_reverse_penny_drop: `${host}/lead/rpd`,

  //end lead status

  get_end_lead_status: `${host}/lead/status`,
  get_lead_current_step: `${host}/lead/current/step`,

  //------------------------------------old-----------------------------------------------------------------------

  REFRESH_INTERVAL: 300000,
};

export default Config;

export const RowCountOptions = [10, 25, 50, 100];
