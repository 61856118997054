import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import SegmentSlab from "../Component/SegmentSlab";
import BrokerageSlab from "../Component/BrokerageSlab";
import { MasterService } from "../Services/MasterService";
import { PATH } from "../Common/Path";
import { getCurrentStep, getPath } from "../Common/utils";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import RadioButtonGroup from "../Component/RadioButtonGroup";

const SegmentPage = (props) => {
    const { steps, setSteps, setLoading, currentStep, setCurrentStep, showConfirmDialog, closeConfirmDialog, hasChanges, setHasChanges  } = useContext(AppContext);
    const [apiMessage, setApiMessage] = useState("");
    const history = useHistory();
    const [formData, setFormData] = useState({})
    const [segments, setSegments] = useState([]);
    const [brokerage, setBrokerage] = useState([]);
    const [showNotesBlock, setShowNotesBlock] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showMessage, setShowMessage] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [showMF, setShowMF] = useState(true);

    useEffect(() => {
        setHasChanges(false)
        setLoading(true);
        Promise.all([MasterService.getSegments(),
             MasterService.getBrokerage(),
             LeadService.getPreviousSegments()])
            .then(datas => {
                let segments = {}, mf_type = null
                if (datas[1] && datas[1].brokerage) {
                    setBrokerage(datas[1].brokerage)
                }
                if (datas[2] && datas[0].segments) {
                    setIsEditable(datas[2].is_editable)
                    setShowMessage(datas[2].show_message)
                    segments = {}
                    let userSelection= false
                    if (datas[2]) {
                        setCurrentStep(getCurrentStep(datas[2].current_step))
                        datas[2].segments.forEach(s => {
                            if(s.selected_options.length > 0){
                                userSelection=true
                            }
                            segments[s.segment_group_code] = s.selected_options;                       
                        })   
                        mf_type = datas[2].mf_type
                    }
                    datas[0].segments.forEach(s => {
                        //Mark Default selected segments or user selected segments
                        if (!segments[s.title_code]) {
                            segments[s.title_code] = userSelection ? [] : s.options.filter(o => o.default_checked).map(o => o.code)
                        }
                        s.defaultChecked = segments[s.title_code]
                    })
                    setSegments(datas[0].segments)
                    handleShowNotes(segments, datas[2].show_message)
                }
                setFormData(formData => ({ ...formData, ...segments, mf_type }))
            })
            .catch((error) => { console.error(error) })
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        steps.forEach(step => {
          if (step.id === currentStep) {
            step.status = 1
          } else if (step.id < currentStep) {
            step.status = 2
          }
        })
        setSteps(steps);
    }, [currentStep])

    const handleShowNotes = (segments, conditions = showMessage) => {
        let show = false;
        Object.entries(segments).forEach(([key, value]) => {
            if (value && value.some(r => conditions[key].indexOf(r) >= 0)) {
                show = true
            }
        });
        setShowNotesBlock(show)
    }

    const mandatoryValidationSubmit = () => {
        let isValid = false;
        let seg = segments.map(s => s.title_code)
        seg.forEach(s => {
            if (formData[s] && formData[s].length > 0) {
                isValid = true
            }
        })
        return isValid
    }

    const mfHandleChange = (event) => {
        setFormData((oldData) => ({ ...oldData, mf_type: event.target.value }));
        setApiMessage("");
        setHasChanges(true);
    };

    const nextClick = () => {
        console.log(segments.selected);
        if (mandatoryValidationSubmit()) {
            setLoading(true);
            let body = {
                details: segments.map(s => ({
                    segment_group_code: s.title_code,
                    selected_options: formData[s.title_code]
                })),
                mf_type: formData.mf_type || RadioGroupOptions.MF_OPTIONS[1].value
            }
            setApiMessage("")
            LeadService.submitSegments(body)
                .then((data) => {
                    if (data.success) {
                        history.push(getPath(data.next_step));
                    }
                    else {
                        setApiMessage(data.error)
                    }

                })
                .catch((error) => { console.error(error) })
                .finally(() => setLoading(false))
        }
    }
    const aboutYouPrevClick = () => {

        if (hasChanges) {
            showConfirmDialog(strings.confirm_msg, undefined, () => {
                closeConfirmDialog();
                history.push(PATH.BANKACCOUNT_PAGE);
              })
        } else {
            history.push(PATH.BANKACCOUNT_PAGE)
        }
    }
    useEffect(()=>{
        if(mandatoryValidationSubmit()){
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    },[formData])

    const handleChange = (segment, selected) => {
        handleShowNotes({ equity: segment === "equity" ? selected : (formData.equity || segments.find(s=>s.title_code ==="equity").defaultChecked || []), commodity: segment === "commodity" ? selected : (formData.commodity || segments.find(s=>s.title_code ==="commodity").defaultChecked || []) })
        setFormData(oldData => ({ ...oldData, [segment]: selected }));   
        if (segment === "equity") {
            setShowMF(selected.includes("mf"))
        }
        setHasChanges(true)
    }
   
    return (
        <div className="inner-section">
            <div className="details">
                <div className="container-fluid">
                    <div className="row">
                        <Header2 />
                        <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
                            <div className="row">
                                <div className="col-md-12 pb-3">
                                    <h5 className="pb-2">{strings.segementhead}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        segments.map((segment, index) => (
                                            <SegmentSlab
                                                key={index}
                                                label={segment.title_label || ""}
                                                mainclass="select-plan"
                                                code={segment.title_code}
                                                subclass="main"
                                                msg={segment.description || ""}
                                                type="checkbox"
                                                labelClass="geekmark"
                                                options={segment.options}
                                                defaultchecked={segment.defaultChecked}
                                                rupeclass="ruppie"
                                                rupee={segment.amount || null}
                                                rupeelive={segment.msp || null}
                                                checkclass="pl-5"
                                                checksubclass="show-inp"
                                                handleChange={handleChange}
                                                disabled={!isEditable}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="">
                                {
                                    showNotesBlock && (
                                        <div className="alert alert-success" role="alert">
                                            {strings.endnotesproof}
                                        </div>
                                    )
                                }
                            </div>

                            {/* <div className="col-md-12 text-justify">
                                <div>
                                    <p className="notecss" >{strings.segmentbrokeragenote}</p>
                                </div>
                            </div> */}

                            {
                                showMF && (
                                    <RadioButtonGroup
                                        tabIndex="1"
                                        label={strings.choose_mf}
                                        options={RadioGroupOptions.MF_OPTIONS}
                                        mainclassName="col-md-12 -mb-3"
                                        divClassName="row"
                                        spanClassName="col-md-6 mf-radio-box"
                                        name="mf_type"
                                        option="mf_type"
                                        onOptionChange={mfHandleChange}
                                        error={apiMessage}
                                        errorMessage={strings.mandatory}
                                        errorclass="text-danger errorfont mh-18"
                                        selected={formData.mf_type || RadioGroupOptions.MF_OPTIONS[1].value}
                                        disabled={false}
                                    />
                                )
                            }
                            <div className="package">
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="pb-4">{strings.brokerage}</h5>
                                        </div>
                                        {
                                            // brokerage.map((brok, index) => (
                                                <BrokerageSlab
                                                    data={brokerage.filter(x=>x.header!==true)}
                                                    head={brokerage.filter(x=>x.header===true)}
                                                   
                                                />
                                           // ))
                                           
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 pt-4">
                                <div className="row mh-42">
                                    <div className="col-md-12 pt-2" >
                                            <div className="text-danger text-center api-errormessage"><label id="txterror" className="txterror"  >{apiMessage}</label> </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 pt-4">
                                        <Button
                                            classes="inp-back"
                                            label={strings.previous}
                                            click={aboutYouPrevClick}
                                        />
                                    </div>
                                    <div className="col-6 pt-3 text-right">
                                        <Button
                                            classes="inp1"
                                            classes1={disabled? "button2" : "button1"}
                                            btndisable={disabled}
                                            label={strings.nextbtn}
                                            click={nextClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};
export default SegmentPage;