import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useScript from "../useScript";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { getPath, getCurrentStep } from "../Common/utils";
import queryString from "query-string";
import { PATH } from "../Common/Path";
import MasterSteps from "../Common/steps";
import AadharDialog from "../Component/AadharDialog";
import PANFetchDialog from "../Component/PANFetchDialog";

const DigiLocker = (props) => {
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    setHasChanges,
  } = useContext(AppContext);
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  useEffect(() => {
    setHasChanges(false);
    if (props.location.search) {
      let qString = queryString.parse(props.location.search);
      const status = qString.status || null;
      let digioId = null;
      if (status && status === "success") {
        digioId = qString.digio_doc_id;
      }
      if (digioId) {
        updateDetails({ digio_doc_id: digioId });
      }
    }
    setCurrentStep(MasterSteps[2].id);
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  const updateDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    LeadService.postDigioResponse(body)
      .then((data) => {
        if (data.success) {
          history.replace(getPath(data.next_step));
          setCurrentStep(getCurrentStep(data.next_step));
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const onDigilockerClick = () => {
    setLoading(true);
    LeadService.submitDigilockerData()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updateDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            let url = data.redirect_url.split("&theme=", 1);
            window.open(
              `${url[0]}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.VERIFICATIONDIGILOCK_PAGE}&theme=${url[1]}`,
              "_self"
            );
          }
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onManualClick = () => {
    setShowDialog(true);
  };

  const submitManualDigilocker = (aadhar) => {
    setLoading(true);
    setShowDialog(false);
    LeadService.submitManualDigilocker({ aadhaar: aadhar })
      .then((data) => {
        if (data.success) {
          history.replace(getPath(data.next_step));
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const showHelp = () => {
    setShowHelpDialog(true);
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
              <div className="row">
                <div className="col-md-12 pb-3">
                  <h5 className="pb-2">
                    {strings.fetchdetails}{" "}
                    <span className="digi-span"> {strings.digilocker}</span>
                  </h5>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="font15 text-justify">
                    <b>{strings.content1}</b>
                  </p>
                  <div className="col-md-12 select-plan">
                    <p className="font15 text-justify">
                      <b>{strings.content2}</b>
                    </p>
                    <p className="font15 text-justify">
                      <b>
                        <i className="fa fa-lock" aria-hidden="true" />
                        {strings.content3}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 pb-3">
                  <div
                    className="digi-btn"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={onDigilockerClick}
                  >
                    {strings.connect_digilocker}
                  </div>
                  <div
                    className="button-link float-left text-primary"
                    onClick={showHelp}
                  >
                    Not able to connect? Click here
                  </div>
                </div>

                <div className="col-md-6 pb-3">
                  <div
                    className="courier"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={onManualClick}
                  >
                    {strings.digi_manually}
                  </div>
                </div>

                <div className="col-md-12 pb-3">
                  Not sure if your aadhaar & mobile are linked?{" "}
                  <a
                    href="https://myaadhaar.uidai.gov.in/verifyAadhaar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* https://resident.uidai.gov.in/verify-email-mobile */}
                    Click here
                  </a>
                </div>
                <div className="col-md-12">
                  <div className="alert alert-success" role="alert">
                    {strings.didinote}
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <div className="inp api-errormessage text-danger">
                    <label id="txterror" name="txterror">
                      {apiMessage}
                    </label>{" "}
                  </div>
                </div>
                {/* <div className="col-md-12 pt-3">
                                    <div className="row">
                                        <div className="col-md-3 pt-4">
                                            <Button
                                                classes="inp-back"
                                                label={strings.previous}
                                                click={PreviousClick}
                                            />
                                        </div>
                                        <div className="col-md-3 pt-3" />
                                        <div className="col-md-6 pt-3 text-right">
                                            <Button
                                                classes="inp"
                                                label={strings.Continue}
                                                classes1="buttonmystyle"
                                                label={strings.next}
                                                click={NextClick}
                                                postfix={<i className="fa fa-chevron-right paddingleft" aria-hidden="true" />}
                                            />
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <AadharDialog
          show={showDialog}
          cancelButtonAction={() => setShowDialog(false)}
          confirmButtonAction={submitManualDigilocker}
        />
        <PANFetchDialog
          show={showHelpDialog}
          cancelButtonAction={() => setShowHelpDialog(false)}
        />
        <Footer />
      </div>
    </div>
  );
};
export default DigiLocker;
